let burgerMenu = document.querySelector(".burger-menu");
let navMenu = document.querySelector(".nav-links");
let linkDropdown = document.querySelector(".children-link");
let dropDownMenu = document.querySelector(".services-links");
burgerMenu.addEventListener("click", function (event) {
  navMenu.classList.toggle("dropdown-menu");
  console.log("button working");
});

linkDropdown.addEventListener("mouseover", function () {
  dropDownMenu.classList.add("services-dropdown");
  dropDownMenu.style.opacity = "1";
});

linkDropdown.addEventListener("mouseout", function () {
  dropDownMenu.style.opacity = "0";
});
